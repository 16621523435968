import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const announcement = () => import("@/views/Announcement.vue");
const allgoodslist = () => import("@/views/AllGoodsList.vue");
const auth = () => import("@/views/Auth/Index.vue");
const password = () => import("@/views/Auth/Password/Index.vue");
const register = () => import("@/views/Auth/Register/Index.vue");
const messages = () => import("@/views/My/Messages/Index.vue");
const myorder = () => import("@/views/My/MyOrder/Index.vue");
const favorites = () => import("@/views/My/Favorites/Index.vue");
const cart = () => import("@/views/Cart.vue");
const commodity = () => import("@/views/Commodity/Index.vue");
const prod = () => import("@/views/Commodity/Prod/Index.vue");





const routes = [
  // 首页
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/announcement",
    name: "announcement",
    component: announcement,
  },
  // 全部商品
  {
    path: "/allgoodslist",
    name: "allgoodslist",
    component: allgoodslist,
  },
  // 登录/注册
  {
    path: "/auth",
    name: "auth",
    // 重定向到手机验证码登录页
    // redirect: "/auth/IdCode",
    component: auth,
    children: [
      // {
      //   path: "idCode",
      //   name: "idCode",
      //   component: () => import("../views/Auth/IdCode/Index.vue"),
      // },
      {
        path: "password",
        name: "password",
        component: password,
      },
      {
        path: "register",
        name: "register",
        component: register,
      },
    ],
  },
  // 消息中心
  {
    path: "/messages",
    name: "messages",
    component: messages,
  },
  // 我的订单
  {
    path: "/myorder",
    name: "myorder",
    component: myorder,
  },
  // 收藏夹
  {
    path: "/favorites",
    name: "favorites",
    component: favorites,
  },
  // 购物车
  {
    path: "/cart",
    name: "cart",
    component: cart,
  },
  // 商品列表
  {
    path: "/commodity",
    name: "commodity",
    component: commodity,
    children: [],
  },
  // 商品详情
  {
    path: "/commodity/:goodsId",
    name: "prod",
    component: prod,
  },
  // 搜索商品
  {
    path: "/commodity/:keyName",
    name: "commodities",
    component: commodity,
  },
];

const router = new VueRouter({
  // 路由跳转后保持页面在最顶部

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },

  routes,
});
// 修改每个路由界面的title
router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  if (title) {
    document.title = title;
  }
  localStorage.setItem('shopNo',"S761475583892")
  localStorage.setItem('beian',"皖ICP备2024061759号")
  next();
});

export default router;
